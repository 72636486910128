
  import BotbuilderApi from 'models/botbuilder_api'

  import { ChevronDownIcon } from '@heroicons/vue/outline'
  import { H4, Label, RadioPanels } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    data: ->
      settings: {}
      chatHistoryLength: '1'
      genderFlag: 'off'
      formalityOptions: [
        {key: 'formal', label: 'formal', description: 'Let the bot answer in a formal way.'}
        {key: 'informal', label: 'informal', description: 'Let the bot answer in an informal way.'}
      ]
      genderOptions: [
        {key: 'off', label: 'no', description: 'Do not impose language restrictions on knowledge-based responses, so they naturally reflect the style and tone of the source documents and the ongoing conversation.'}
        {key: 'on', label: 'yes', description: 'Use inclusive, gender-neutral language whenever possible.'}
      ]
      historyOptions: [
        {key: 'on', label: 'yes', description: 'Take the user’s chat history into account when answering questions, e.g. to ask follow-up questions.'}
        {key: 'off', label: 'no', description: 'Do not take the user’s chat history into account when answering questions. This setting may improve the specificity of answers.'}
      ]

    created: ->
      @loadSettings()

    methods:
      loadSettings: ->
        @settings = await BotbuilderApi.getKnowledgeSettings()
        @setChatHistoryLength()
        @setGenderFlag()
      setChatHistoryLength: ->
        @chatHistoryLength = if @settings.chatHistoryLength == 0 then 'off' else 'on'
      setGenderFlag: ->
        @genderFlag = if @settings.avoidGenderSpecificLanguage then 'on' else 'off'
      updateChatHistoryLength: ->
        @settings.chatHistoryLength = if @chatHistoryLength == 'off' then 0 else 1
        @updateSettings()
      updateGenderFlag: ->
        @settings.avoidGenderSpecificLanguage = if @genderFlag == 'on' then true else false
        @updateSettings()
      updateSettings: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> BotbuilderApi.updateKnowledgeSettings(@settings)), 1000)

    components:
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      H4: H4
      Label: Label
      RadioPanels: RadioPanels
