
  import { alert, confirm } from 'helpers'

  import CommandPalette from './command_palette.vue'
  import Sidebar from 'components/botbuilder/sidebar.vue'
  import TriggerIntentEditor from './trigger_intent_editor.vue'
  import UserIntentEditor from './user_intent_editor.vue'
  import ActionEditor from './action_editor.vue'
  import BotIntentEditor from './bot_intent_editor.vue'
  import { ActionMenu, Alert, Badge, Button, H3, Input, Modal } from 'components/generic'
  import { FireIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      entity: Object
      show: Boolean
      displaySettings: Object

    emits: ['close', 'remove', 'rebuild', 'set-node', 'display-settings-changed']

    data: ->
      label: ''
      hints: []
      editingLabel: false
      display: true
      selectNodeForMerge: false
      nodeToBeMergedInto: null
      XIcon: XIcon
      colorPerSeverity:
        fatal: 'red'
        error: 'red'
        warn: 'yellow'
        info: 'blue'
        suggestion: 'green'

    computed:
      actionMenuItems: ->
        [
          {icon: XIcon, label: 'Delete node', method: @remove}
          {icon: FireIcon, label: 'Merge into other user intent', method: => @selectNodeForMerge = true} if @entity?.type == 'UserIntent'
        ].compact()

    watch:
      'entity.key': ->
        @loadHints()
        @recreateInnerEditor()
      entity:
        deep: true
        handler: ->
          return if !@entity
          @setCopies()

    created: ->
      @loadHints()

    methods:
      showInfo: ->
        alert("Key: #{@entity.key}");
      editLabel: ->
        @editingLabel = true
        @$nextTick => @$refs.labelInput.focus()
      cancelLabelEditing: ->
        @editingLabel = false
        @label = @entity.label
      updateLabel: ->
        @editingLabel = false
        @entity.update(label: @label).save()
      setCopies: ->
        @label = @entity.label
      loadHints: ->
        return unless @entity?
        @$root.currentBot.getHints(
          moduleKey: @entity.moduleKey
          identifier: @entity.key
          limit: 10
        )
          .then (hints) => @hints = hints
      remove: ->
        return unless await confirm('Do you really want to delete this node?')
        @$emit('remove', @entity)
      showHint: (hint) ->
        message = hint.specificMessage + "\n\n" + hint.description
        alert(message)
      recreateInnerEditor: ->
        @display = false
        @$nextTick => @display = true
      promptMergeConfirmation: (node) ->
        @selectNodeForMerge = false
        @nodeToBeMergedInto = node
        @$refs.mergeConfirmationModal.open()
      merge: ->
        @$refs.mergeConfirmationModal.close()
        @nodeToBeMergedInto.merge(@entity)
          .then =>
            @$emit('rebuild')
            @$emit('set-node', @nodeToBeMergedInto)

    components:
      ActionEditor: ActionEditor
      ActionMenu: ActionMenu
      Alert: Alert
      Badge: Badge
      BotIntentEditor: BotIntentEditor
      Button: Button
      CommandPalette: CommandPalette
      H3: H3
      Input: Input
      Modal: Modal
      Sidebar: Sidebar
      TriggerIntentEditor: TriggerIntentEditor
      UserIntentEditor: UserIntentEditor
