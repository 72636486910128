import Project from 'models/project'

export default class DeletedDialogModule
  constructor: (@botConfig, data) ->
    @status = 'DELETED'
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.moduleKey
    @label = data.label || ''
    @description = data.description || ''
    @statusEnvironments = data.statusEnvironments || {}

  clone: ->
    new DeletedDialogModule(@botConfig, @export)

  Object.defineProperties @prototype,
    botId:
      get: -> @botConfig.id
    environments:
      get: -> []
    activeEnvironments:
      get: ->
        Project.ENVIRONMENT_KEYS
          .filter (env) => (@statusEnvironments || {})[env] == 'ACTIVE'
    export:
      get: ->
        moduleKey: @key
        label: @label
        description: @description
        statusEnvironments: @statusEnvironments
