export default class TableConfiguration
  constructor: (data) ->
    # columns expected in the form of
    # [ {key: '...', label: '...', defaultShow: <Bool>(, show: <Bool>)}, ... ]
    @columns = data.columns
    @columns.forEach (col) -> col.show ||= col.defaultShow

  Object.defineProperties @prototype,
    columnsByKey:
      get: ->
        Object.fromEntries(
          @columns.map (col) -> [col.key, col]
        )

