
  import ObjectProcessor from 'models/object_processor.coffee'
  import PlatformApi from 'models/platform_api.coffee'
  import Bot from 'models/bot.coffee'
  import DialogModule from 'models/dialog_module.coffee'
  import DialogNode from 'models/dialog_node.coffee'
  import Graph from 'models/graph.coffee'
  import { prompt } from 'helpers'
  import NodeDisplay from './node_display.vue'
  import EdgeDisplay from './edge_display.vue'
  import NodeEditor from './node_editor.vue'
  import Tryme from './tryme.vue'
  import Navigation from '../navigation.vue'
  import CommandPalette from './command_palette.vue'
  import ContextParameterDetail from 'components/botbuilder/context_parameters/context_parameter_detail.vue'
  import { Button, ScreenOverlay } from 'components/generic'
  import { LockClosedIcon, LockOpenIcon, HomeIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot
      userSettings: Object

    data: ->
      graph: new Graph()
      dims:
        colWidth: 300
        rowHeight: 100
        nodeWidth: 180
        initiativeGap: 30
        nodeHeight: 50
      nodeRefs: []
      currentEntity: null
      currentParameterKey: null
      select: null
      displayTryme: false
      grabbing: false

    computed:
      displayEditor: ->
        @currentEntity? && !@select?
      displayTrymeButton: ->
        !@displayEditor && !@displayTryme
      displaySettings: ->
        @userSettings?.moduleEditor?.bots?[@bot.id]?.modules?[@$route.params.moduleKey]?.displaySettings

    watch:
      '$route.params.moduleKey': ->
        @unsetModule()
        @init()
      displayEditor: ->
        @displayTryme = false if @displayEditor
      currentEntity: ->
        if @currentEntity?
          @$router.push(hash: "##{@currentEntity.key}") if @displayEditor
        else
          @$router.push(hash: '')
      select: ->
        if @select? then @displayTryme = false

    created: ->
      @init()

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      @unsetModule()
      window.removeEventListener('resize', @setHeight)

    beforeUpdate: ->
      @nodeRefs = []

    methods:
      init: ->
        @initSettings()
        @currentEntity = null
        return unless @$route.params.moduleKey?
        @bot.config.fetchModule(@$route.params.moduleKey).then (module) =>
          @$root.currentModule = module
          @adoptOldPinnings()
          @rebuildGraph()
          @edit(@$route.hash[1..]) if @$route.hash
          window.breadcrumbs.append(text: @$root.currentModule.label)
      adoptOldPinnings: ->
        Object.entries(@$root.currentModule.nodeMeta).forEach ([key, data]) =>
          if data.pinned? && !@displaySettings.nodes[key]?.pinned?
            @displaySettings.nodes[key] ||= {}
            @displaySettings.nodes[key].pinned = data.pinned
        @$emit('settings-changed')
      initSettings: ->
        @userSettings.moduleEditor ||= {}
        @userSettings.moduleEditor.bots ||= {}
        @userSettings.moduleEditor.bots[@bot.id] ||= {}
        @userSettings.moduleEditor.bots[@bot.id].modules ||= {}
        @userSettings.moduleEditor.bots[@bot.id].modules[@$route.params.moduleKey] ||= {}
        @userSettings.moduleEditor.bots[@bot.id].modules[@$route.params.moduleKey].displaySettings ||= {}
        @userSettings.moduleEditor.bots[@bot.id].modules[@$route.params.moduleKey].displaySettings.nodes ||= {}
        @displaySettings.showOutgoingExternalNodes = true if !@displaySettings.showOutgoingExternalNodes?
        @displaySettings.showIncomingNodes = false if !@displaySettings.showIncomingNodes?
        @displaySettings.groupNodeReferences = false if !@displaySettings.groupNodeReferences?
        @displaySettings.alignNodeTypes = true if !@displaySettings.alignNodeTypes?
        @displaySettings.showEnvironment = false if !@displaySettings.showEnvironment?
      unsetModule: ->
        @$root.currentModule?.clearNodes()
        @$root.currentModule = null
      startSearch: ->
        @select = 'all'
      setHeight: ->
        top = @$refs.wrapper.getBoundingClientRect().top
        @$refs.wrapper.style.height = window.innerHeight - top + 'px'
      rebuildGraph: ->
        @graph = new Graph(@$root.currentModule, @displaySettings)
        @graph.layout(@dims)
      reloadGraph: ->
        @bot.config.fetchModule(@$root.currentModule.key)
          .then => @rebuildGraph()
      setNodeRef: (el) ->
        @nodeRefs.push(el) if el?
      addActionOnEdge: (edge) ->
        originalTarget = edge.entity
        actionLabel = await prompt('Please enter a label for the new dialog action:', '')
        return if actionLabel == ''
        @$root.currentModule.addAction(label: actionLabel, target: originalTarget.export)
          .then (newAction) =>
            originalTarget.set(newAction)
            originalTarget.host.save()
              .then =>
                @rebuildGraph()
                @edit(newAction.key)
      addNodeAfterNode: (node) ->
        @currentEntity = node.entity
        switch node.type
          when 'bot'
            @select = 'forBotIntent'
          when 'user', 'trigger'
            @select = 'forUserIntent'
      cancelSelect: ->
        @select = null
        @currentEntity = null
      selectNode: (entity) ->
        if @select == 'all'
          @edit(entity.key)
          return
        @connectNode(@currentEntity, entity)
        @select = null
        @currentEntity = null
        @rebuildGraph()
      createNode: (type, {query = null, connect = false} = {}) ->
        if !query
          query = await prompt('Please enter a label for the new intent:')
          return if query == ''
        @$root.currentModule["add#{type}"](label: query)
          .then (entity) =>
            @connectNode(@currentEntity, entity) if connect
            @select = null
            @currentEntity = null
            @rebuildGraph()
            @edit(entity.key)
      connectNode: (existingEntity, newEntity) ->
        switch existingEntity?.type
          when 'UserIntent' then existingEntity.target.set(newEntity)
          when 'BotIntent' then existingEntity.addPriming(newEntity)
        existingEntity?.save()
      edit: (node) ->
        if typeof node == 'string'
          node = @graph.nodeIndex[node]
        else if node instanceof DialogNode
          if @graph.nodeIndex[node.key]?
            node = @graph.nodeIndex[node.key]
          else # node is of other module
            @redirectToNodeOfOtherModule(node)
            return
        node = node.refNode if node.refNode?
        if node.ofOtherModule
          @redirectToNodeOfOtherModule(node.entity)
          return
        @select = null
        @$nextTick =>
          @currentEntity = node.entity
          @$nextTick => # make sure node is centered in the remaining area
            @scrollToNode(node)
      redirectToNodeOfOtherModule: (node) ->
        @currentEntity = null # avoid resetting of currentEntity on init to remove hash
        @$nextTick =>
          @$router.push(name: 'module', params: {moduleKey: node.moduleKey}, hash: "##{node.key}")
      removeCurrentEntity: (entity) ->
        entity.delete().then =>
          @rebuildGraph()
          @closeEditor()
      closeEditor: ->
        @currentEntity = null
      closeSidebar: ->
        @closeEditor()
        @displayTryme = false
      scrollToNode: (node) ->
        el = @nodeRefs.map((ref) -> ref.$el).find (el) -> el.getAttribute('node-id') == node.id
        nodeX = el.offsetLeft + @dims.nodeWidth / 2
        nodeY = el.offsetTop + @dims.nodeHeight / 2
        scrollLeft = nodeX - @$refs.graphAreaWrapper.offsetWidth / 2
        scrollTop = nodeY - @$refs.graphAreaWrapper.offsetHeight / 2
        @$refs.graphAreaWrapper.scroll(left: scrollLeft, top: scrollTop, behavior: 'smooth')
      drag: (e) ->
        return unless @grabbing
        e.preventDefault()
        left = @$refs.graphAreaWrapper.scrollLeft
        top = @$refs.graphAreaWrapper.scrollTop
        @$refs.graphAreaWrapper.scroll(left: left - e.movementX, top: top - e.movementY)
      editContextParameter: (parameterKey) ->
        @currentParameterKey = parameterKey
        @$refs.contextParameterOverlay.open()
      isCurrentEdge: (edge) ->
        return false if !@currentEntity
        edge.entity?.host == @currentEntity || edge.connection.last?.endNode?.entity == @currentEntity
      isCurrentNode: (node) ->
        return false if !@currentEntity
        node.entity == @currentEntity
      displayedSettingsChanged: ->
        @$emit('settings-changed')
        @rebuildGraph()

    components:
      Button: Button
      Tryme: Tryme
      CommandPalette: CommandPalette
      ContextParameterDetail: ContextParameterDetail
      EdgeDisplay: EdgeDisplay
      HomeIcon: HomeIcon
      LockClosedIcon: LockClosedIcon
      LockOpenIcon: LockOpenIcon
      Navigation: Navigation
      NodeDisplay: NodeDisplay
      NodeEditor: NodeEditor
      ScreenOverlay: ScreenOverlay
