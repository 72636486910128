
  import Bot from 'models/bot'
  import BotbuilderApi from 'models/botbuilder_api'
  import DialogModule from 'models/dialog_module'
  import TableConfiguration from 'models/table_configuration'
  import { railsEnv } from 'rails_data'
  import { download } from 'helpers'

  import Navigation from '../navigation.vue'
  import CommandPalette from './command_palette.vue'
  import ModuleTableRow from './module_table_row.vue'
  import { DocumentationLink, PageHeader, Table, TableConfigurator, TableHeadCell, TableHeadRow, TableWrapper } from 'components/generic'

  export default
    props:
      bot: Bot
      userSettings: Object

    data: ->
      search: false
      modules: []
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'description', label: 'Description', defaultShow: true}
          {key: 'environment', label: 'Environment', defaultShow: false}
        ]
      )

    computed:
      settings: ->
        @userSettings?.coreBehaviorList?.bots?[@bot.id]

    watch:
      'bot.config.coreBehaviours': -> @setModules()

    created: ->
      @initSettings()
      @setModules()
      @bot.loadMetaInfo()

    methods:
      initSettings: ->
        @userSettings.coreBehaviorList ||= {}
        @userSettings.coreBehaviorList.bots ||= {}
        @userSettings.coreBehaviorList.bots[@bot.id] ||= {}
        @settings.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          @tableConfig.columnsByKey[col.key].show = @settings.columns[col.key] if @settings.columns[col.key]?
      setModules: ->
        @modules = @bot.config.coreBehaviours
          .filter (module) => !(railsEnv == 'production' && ['Inactivity'].includes(module.type))
          .sortByString('label')
      goTo: (node) ->
        @$router.push(name: 'module', params: {moduleKey: node.moduleKey}, hash: "##{node.key}")
        @search = false
      saveSettings: (column) ->
        @tableConfig.columns.forEach (col) =>
          @settings.columns[col.key] = @tableConfig.columnsByKey[col.key].show
        @$emit('settings-changed')

    components:
      CommandPalette: CommandPalette
      DocumentationLink: DocumentationLink
      Navigation: Navigation
      ModuleTableRow: ModuleTableRow
      PageHeader: PageHeader
      Table: Table
      TableConfigurator: TableConfigurator
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableWrapper: TableWrapper
