
  import TableConfiguration from 'models/table_configuration'

  import Dropdown from './dropdown.vue'
  import Switch from './switch.vue'
  import { CogIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: TableConfiguration
      columnsLabel:
        type: String
        default: 'Show columns'

    methods:
      toggleDropdown: ->
        @$refs.dropdown.toggle()
      update: ->
        @$emit('update:modelValue', @modelValue)

    components:
      CogIcon: CogIcon
      Dropdown: Dropdown
      Switch: Switch
