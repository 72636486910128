const colors = require('tailwindcss/colors')
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: {
    files: [
      './app/views/**/*',
      './app/assets/javascripts/**/*',
      './app/javascript/**/*',
    ],
  },
  blocklist: ['collapse'],
  theme: {
    colors: {
      transparent: 'transparent',
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
    },
    extend: {
      minHeight: {
        28: '7rem',
        32: '8rem',
        96: '24rem',
      },
      minWidth: {
        '1/2': '50%',
        96: '24rem',
        md: '28rem',
        lg: '32rem',
        xl: '36rem',
        '2xl': '42rem',
        '3xl': '48rem',
        '4xl': '56rem',
        '5xl': '64rem',
      },
      maxWidth: {
        40: '10rem',
        80: '20rem',
        160: '40rem',
        '1/3': '33%',
        '1/2': '50%',
      },
      margin: {
        88: '22rem',
      },
      zIndex: {
        '-1': '-1',
        1: '1',
        2: '2',
        1050: '1050', // needed for tailwind UI modal together with bootstrap modal
      },
      colors: {
        'EerieBlack': '#1c1c1c',
        'MediumBlack': '#2b2b2b',
        'BlackOlive': '#3a3a3a',
        'DavyGrey': '#595959',
        MercuryGrey: {
          900: '#1A1A1A',
          800: '#3A3A3A',
          700: '#585858',
          600: '#6C6C6C',
          500: '#949494',
          400: '#B4B4B4',
          300: '#D8D8D8',
          200: '#E8E8E8',
          100: '#F2F2F2',
          50: '#F9F9F9',
          0: '#FFFFFF',
        },
        MercuryBlue: {
          900: '#005A99',
          800: '#007ABB',
          700: '#008BCE',
          600: '#009EE2',
          500: '#16ABEA',
          400: '#2DB9F3',
          300: '#52C5F4',
          200: '#82D6F7',
          100: '#B3E6FA',
          50: '#E1F5FD',
        },
        MercuryGreen: {
          900: '#176B00',
          800: '#498d00',
          700: '#5fa000',
          600: '#76b400',
          500: '#86C400',
          400: '#99cd3f',
          300: '#add666',
          200: '#c5e194',
          100: '#DDEDBE',
          50: '#f1f8e5',
        },
        MercuryYellow: {
          900: '#ff6e01',
          800: '#ff8e00',
          700: '#ffa000',
          600: '#FFB400',
          500: '#ffc007',
          400: '#ffca28',
          300: '#ffd54f',
          200: '#ffe082',
          100: '#ffecb3',
          50: '#fff8e1',
        },
        MercuryRed: {
          900: '#C1340A',
          800: '#da4114',
          700: '#e84918',
          600: '#F6511D',
          500: '#ff5621',
          400: '#ff6f42',
          300: '#ff8a65',
          200: '#ffab91',
          100: '#FFCCBC',
          50: '#fbe9e7',
        },
        Skobeloff: {
          // 900: '#065155',
          // 800: '#06737f',
          // 700: '#068797',
          // picked from schemecolor.com:
          // 900: '#045159',
          // 800: '#055C66',
          // 700: '#056872',
          // 600: '#06737f', // base color
          // 500: '#1F818C',
          // 400: '#388F99',
          // 300: '#6AABB2',
          // 200: '#9BC7CC',
          // 100: '#CDE3E5',
          // 50: '#E6F9FA',
          // adjusted with meyerweb.com/eric/tools/color-blend
          900: '#045159',
          800: '#055C66',
          700: '#056872',
          600: '#06737f', // base color
          500: '#2B8994',
          400: '#51A0A8',
          300: '#76B6BD',
          200: '#9BCCD1',
          100: '#C1E3E6',
          50: '#E6F9FA',
          // complete list from https://uicolors.app:
          // 900: '#0a616f',
          // 800: '#06737f',
          // 700: '#0096a4',
          // 600: '#00becc',
          // 500: '#00eef4',
          // 400: '#03fff1',
          // 300: '#51ffed',
          // 200: '#98fff2',
          // 100: '#c8fff7',
          // 50: '#e9fffc',
        },
        Avocado: {
          // 900: '#617513',
          // 800: '#769c27',
          // 700: '#82b332',
          // // taken from https://uicolors.app:
          900: '#435017',
          800: '#505f15',
          700: '#617513', // base color
          600: '#839d13',
          500: '#a8c51d',
          400: '#c6df3c',
          300: '#daec6a',
          200: '#ebf5a1',
          100: '#f5facd',
          50: '#fcfde8',
        },
        CornellRed: {
          // 900: '#b21e1e',
          // 800: '#c12929',
          // 700: '#ce3030',
          // // taken from https://uicolors.app:
          900: '#7d1f1f',
          800: '#971d1d',
          700: '#b21e1e', // base color
          600: '#d92929',
          500: '#ec4747',
          400: '#f67373',
          300: '#faa7a7',
          200: '#fdcbcb',
          100: '#fee2e2',
          50: '#fef2f2',
        },
        MagentaViolet: {
          900: '#32296b',
          800: '#41337e',
          700: '#4a3988',
          600: '#554191', // base color
          500: '#5d4697',
          400: '#735fa6',
          300: '#8b7bb5',
          200: '#aca0ca',
          100: '#ccc6df',
          50: '#ebe8f2',
        },
      },
      animation: {
        background: 'background 3s ease infinite',
      },
      keyframes: {
        background: {
          '0%': {
            backgroundSize: '400%',
          },
          '50%': {
            backgroundPosition: '100% 50%',
            backgroundSize: '400%',
          },
          '100%': {
            backgroundPosition: '0% 50%',
            backgroundSize: '400%',
          },
        },
      },
      screens: {
        '3xl': '1920px',
        '4xl': '2048px',
      },
    },
  },
  variants: {
    backgroundColor: ({ after }) => after(['disabled', 'even', 'odd']),
    color: ({ after }) => after(['disabled']),
    rounded: ({ after }) => after(['first', 'last']),
  },
  plugins: [
    require('@tailwindcss/typography'),
    plugin(function({ addUtilities }) {
      const newUtilities = {
        '.tw-hidden': {display: 'none'}, // as replacement for bootstrap's "hidden" which has !important
        '.tabular-nums': {'font-variant-numeric': 'tabular-nums'}
      }
      addUtilities(newUtilities, ['responsive'])
    })
  ],
}
