import PlatformApi from 'models/platform_api.coffee'
import { availableLanguages } from 'rails_data'

export default class Project
  @ENVIRONMENTS = [
    {key: 'DEV', label: 'DEV'}
    {key: 'TEST', label: 'TEST'}
    {key: 'LIVE', label: 'LIVE'}
  ]

  @ENVIRONMENT_KEYS = @ENVIRONMENTS.map (env) -> env.key

  @LANGUAGE_VARIATIONS = [
    {key: 'DEFAULT', label: 'Standard'}
    {key: 'EASY', label: 'Easy language'}
  ]

  @AVAILABLE_LANGUAGES = Object.entries(availableLanguages).map ([code, label]) ->
    {key: code, label: label}

  @load: (id) ->
    PlatformApi.Webapp.getProject(id)
      .then (data) -> new Project(data)

  constructor: (data) ->
    @id = data.id
    @uuid = data.uuid
    @languages = data.languages || []
    @agentNotificationSettings = {email: {enabled: true}, slack: {enabled: false}}
    @update(data)

  update: (data) ->
    @name = data.name if data.name?
    @description = data.description if data.description?
    @ownerId = data.owner_id if data.owner_id?
    @languages = data.bc2_languages if data.bc2_languages?
    @languageVariations = data.language_variations if data.language_variations?
    if data.max_languages?
      @maxLanguages = if data.max_languages == '.inf' then Infinity else data.max_languages
    @messageRetentionTime = data.message_retention_time if data.message_retention_time?
    @agentNotificationSettings.email = data.agent_notification_settings.email if data.agent_notification_settings?.email?
    @agentNotificationSettings.slack = data.agent_notification_settings.slack if data.agent_notification_settings?.slack?

  save: =>
    PlatformApi.Webapp.updateProject(@)

  delete: ->
    PlatformApi.Webapp.deleteProject(@)

  Object.defineProperties @prototype,
    slackConnected:
      get: ->
        !!@agentNotificationSettings.slack.ok
    export:
      get: ->
        name: @name
        description: @description
        owner_id: @ownerId
        bc2_languages: @languages
        language_variations: @languageVariations
        message_retention_time: @messageRetentionTime
        agent_notification_settings: @agentNotificationSettings
