
  export default
    data: ->
      cellRefs: []

    methods:
      setCellRef: (el) ->
        @cellRefs.push(el) if el?
      sort: (component) ->
        @$emit('sort')
        @cellRefs.forEach (cell) ->
          if cell != component
            cell.direction = null
