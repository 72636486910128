
  import Project from 'models/project'

  import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
  import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'

  export default
    props:
      variationsEnabled:
        type: Boolean
        default: false
      align:
        type: String
        default: 'right'

    data: ->
      languageVariationLabels = Object.fromEntries(Project.LANGUAGE_VARIATIONS.map((f) -> [f.key, f.label]))

      selectedItem: null
      languageVariationLabels: languageVariationLabels
      languagesForSelector: Object.entries(@$root.languages)
        .map ([code, lang]) =>
          if @variationsEnabled
            if @$root.languageVariations[code]?.length > 1
              [key: code, label: lang, isParent: true].concat(
                @$root.languageVariations[code].map (variation) =>
                  {key: "#{code}-#{variation}", label: languageVariationLabels[variation], subordinate: true}
              )
            else
              {key: "#{code}-DEFAULT", label: lang}
          else
              {key: code, label: lang}
        .flat()

    computed:
      supplementedCollection: ->
        if @languagesForSelector.map((item) => item.key).includes(@selectedItem.key)
          @languagesForSelector
        else
          [@selectedItem].concat(@languagesForSelector)
      buttonLabelClass: ->
        if !@selectedItem? || !@selectedItem.key
          'text-gray-400'
        else
          ''

    watch:
      '$root.currentLanguage': -> @setSelectedItem()
      '$root.currentLanguageVariation': -> @setSelectedItem()

    created: ->
      @setSelectedItem()

    methods:
      setSelectedItem: ->
        key = if @variationsEnabled then "#{@$root.currentLanguage}-#{@$root.currentLanguageVariation}" else @$root.currentLanguage
        selectedItem = @languagesForSelector.find (item) => item.key == key
        selectedItem ||= key: key
        @selectedItem = selectedItem
      itemSelected: (item) ->
        if @variationsEnabled
          @$root.currentLanguage = item.key.split('-')[0]
          @$root.currentLanguageVariation = item.key.split('-')[1]
        else
          @$root.currentLanguage = item.key
      itemLabel: (item) ->
        return '' if !item?
        item.label || item.key
      buttonLabel: (item) ->
        languageLabel = @$root.languages[item.key.split('-')[0]]
        if @variationsEnabled && @$root.languageVariations[item.key.split('-')[0]]?.length > 1
          "#{languageLabel} (#{@languageVariationLabels[item.key.split('-')[1]]})"
        else
          languageLabel
      open: ->
        @$refs.button.$el.click()
      focus: ->
        @open()

    components:
      Listbox: Listbox,
      ListboxButton: ListboxButton,
      ListboxOption: ListboxOption,
      ListboxOptions: ListboxOptions,
      CheckIcon: CheckIcon,
      SelectorIcon: SelectorIcon,
