
  import DialogTarget from 'models/dialog_target.coffee'
  import FacebookMenuItem from 'models/facebook_menu_item.coffee'
  import WidgetMenuItem from 'models/widget_menu_item.coffee'

  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { ActionMenu, Input, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: [FacebookMenuItem, WidgetMenuItem]
      useDefaultLanguage:
        type: Boolean
        default: true

    emits: ['remove']

    data: ->
      item: @modelValue
      target: null
      targetIsSet: null
      actionMenuItems: [
        {icon: XIcon, label: 'Remove', method: => @$emit('remove')}
      ]

    computed:
      channelType: ->
        @item.menu.channel.type

    created: ->
      @setTarget()

    methods:
      setTarget: ->
        @target = new DialogTarget(
          {}
          moduleKey: @item.moduleKey
          nodeKey: @item.nodeKey
        )
        @targetIsSet = true
      updateTarget: ->
        @item.payload = "#{@target.moduleKey}/#{@target.nodeKey}"
        @update()
      update: ->
        @$emit('update:modelValue', @item)

    components:
      ActionMenu: ActionMenu
      Input: Input
      Label: Label
      TargetEditor: TargetEditor
