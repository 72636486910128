import PlatformApi2 from 'models/platform_api2.coffee'
import Channel from 'models/channel.coffee'
import WidgetMenu from 'models/widget_menu.coffee'
import WidgetTeaserConfig from 'models/widget_teaser_config.coffee'
import { railsEnv } from 'rails_data'

export default class WebChannel extends Channel
  @STYLE_DEFAULTS = [
    {attr: 'bodyFont', default: '"Inter", sans-serif'}
    {attr: 'bodyColor', default: '#F9F8F8'}
    {attr: 'bodyShadowColor', default: '#000000'}
    {attr: 'bodyShadowSize', default: 10}
    {attr: 'bodyBorderRadius', default: 8}
    {attr: 'headerColor', default: '#3A3A3A'}
    {attr: 'toggleBackgroundColor', default: '#009EE2'}
    {attr: 'bubbleFontSize', default: 15}
    {attr: 'bubbleCornerRadius', default: 5}
    {attr: 'bubbleBotBackgroundColor', default: '#E8E8E8'}
    {attr: 'bubbleBotFontColor', default: '#1c1c1c'}
    {attr: 'bubbleUserBackgroundColor', default: '#009ee2'}
    {attr: 'bubbleUserFontColor', default: '#ffffff'}
    {attr: 'bubbleAgentBackgroundColor', default: '#86c400'}
    {attr: 'bubbleAgentFontColor', default: '#ffffff'}
    {attr: 'bubbleKnowledgeBackgroundColor', default: '#E8E8E8'}
    {attr: 'bubbleKnowledgeFontColor', default: '#1c1c1c'}
    {attr: 'bubbleTeaserBackgroundColor', default: '#9000e1'}
    {attr: 'bubbleTeaserFontColor', default: '#ffffff'}
    {attr: 'bubbleDateBackgroundColor', default: '#e1f5fd'}
    {attr: 'bubbleDateFontColor', default: '#1c1c1c'}
    {attr: 'bubbleTimestampFontColor', default: '#969696'}
    {attr: 'bubbleQuickCornerRadius', default: 24}
    {attr: 'bubbleQuickBackgroundColor', default: '#ffffff'}
    {attr: 'bubbleQuickFontColor', default: '#009EE2'}
    {attr: 'bubbleQuickHoverBackgroundColor', default: '#009EE2'}
    {attr: 'bubbleQuickHoverFontColor', default: '#ffffff'}
    {attr: 'bubbleQuickShadowColor', default: '#000000FF'}
    {attr: 'bubbleQuickShadowBlur', default: 3}

    {attr: 'bubblePostbackBackgroundColor', default: '#ffffff'}
    {attr: 'bubblePostbackFontColor', default: '#009EE2'}
    {attr: 'bubblePostbackHoverBackgroundColor', default: '#E7E7E7'}
    {attr: 'bubblePostbackHoverFontColor', default: '#009EE2'}

    {attr: 'bubbleTeaserButtonCornerRadius', default: 18}
    {attr: 'bubbleTeaserButtonBackgroundColor', default: '#ffffff'}
    {attr: 'bubbleTeaserButtonFontColor', default: '#818181'}
    {attr: 'bubbleTeaserButtonHoverBackgroundColor', default: '#818181'}
    {attr: 'bubbleTeaserButtonHoverFontColor', default: '#ffffff'}

    {attr: 'teaserButtonCornerRadius', default: 18}
    {attr: 'teaserButtonBackgroundColor', default: '#ffffff'}
    {attr: 'teaserButtonFontColor', default: '#585858'}
    {attr: 'teaserButtonHoverBackgroundColor', default: '#00a2ea'}
    {attr: 'teaserButtonHoverFontColor', default: '#ffffff'}
    {attr: 'teaserButtonShadowColor', default: '#00000080'}
    {attr: 'teaserButtonShadowBlur', default: 4}
  ]

  @DEFAULT_COOKIE_LIFETIME = '540'

  constructor: (data = {}) ->
    super(data)

  setConfig: (data = {}) ->
    @initialBehavior = if data.initialBehavior
      data.initialBehavior
    else if data.defaultMode # use legacy attribute
      desktop: {start: data.defaultMode}
      mobile: {start: data.defaultMode}
    else
      desktop: {start: 'closed'}
      mobile: {start: 'closed'}
    @teaserMessages = (data.teaserMessages || []).map (config) => new WidgetTeaserConfig(@, config)
    @menu = new WidgetMenu(@, data.menu)
    @widgetConfig =
      cookieLifetime: data.cookieLifetime || @constructor.DEFAULT_COOKIE_LIFETIME
      logoUrl: data.logoUrl || ''
      avatarUrl: data.avatarUrl || ''
      emoji: if data.emoji? then data.emoji else false
      entryGate: if data.entryGate? then data.entryGate else false
      entryGateFields: data.entryGateFields || {
        userDataFields:
          name: false
          email: false
          company: false
          phone: false
        optIn:
          message: ''
      }
      toggleOpenUrl: data.toggleButton?.open || ''
      toggleClosedUrl: data.toggleButton?.close || ''
      removeFooter: if data.removeFooter? then data.removeFooter else false
      knowledgeFlag: if data.knowledgeFlag? then data.knowledgeFlag else false
      typingEffect: if data.typingEffect? then data.typingEffect else false # default for existing channels
      uploadEnabled: if data.uploadEnabled? then data.uploadEnabled else false
      languageMenu: if data.languageMenu?
        data.languageMenu
      else
        display: false
        languages: Globals.project.languages
        languageVariations: Globals.project.language_variations
    # set styling defaults:
    style = data.style || {}
    @widgetStyles = {}
    WebChannel.STYLE_DEFAULTS.forEach (entry) =>
      if entry.attr == 'bubbleKnowledgeBackgroundColor' && !style[entry.attr]?
        @widgetStyles[entry.attr] = style.bubbleBotBackgroundColor || entry.default
      else if entry.attr == 'bubbleKnowledgeFontColor' && !style[entry.attr]?
        @widgetStyles[entry.attr] = style.bubbleBotFontColor || entry.default
      else
        @widgetStyles[entry.attr] = if style[entry.attr]? then style[entry.attr] else entry.default

  saveDefaultConfig: ->
    @setConfig(typingEffect: true) # default for new channels
    @saveConfig()

  uploadAsset: (name, file) ->
    if file == null
      @widgetConfig["#{name}Url"] = ''
      @saveConfig()
      return
    PlatformApi2.uploadChannelAsset(@, name, file).then (data) =>
      @widgetConfig["#{name}Url"] = data.url
      @saveConfig()

  uploadStyle: ->
    file = new File([@stylesheet], 'style.css', type: 'text/css')
    PlatformApi2.uploadChannelAsset(@, 'style', file)

  removeDefaultLanguageTeaser: ->
    index = @teaserMessages.findIndex (teaser) => teaser.language == @defaultLanguage
    return if index == -1
    @teaserMessages.splice(index, 1)

  Object.defineProperties @prototype,
    config:
      get: ->
        Object.assign(
          {}
          @configExport
          # some generic attributes
          defaultLanguage: @defaultLanguage
          enabled: @enabled
          optIn: @optInRequired
          # remaining WEB-specific stuff
          initialBehavior: @initialBehavior
          style: @widgetStyles
          teaserMessages: @teaserMessages.map (teaser) -> teaser.export
          menu: @menu.export
        )
    configExport:
      get: ->
        cookieLifetime: @widgetConfig.cookieLifetime
        logoUrl: @widgetConfig.logoUrl
        avatarUrl: @widgetConfig.avatarUrl
        emoji: @widgetConfig.emoji
        entryGate: @widgetConfig.entryGate
        entryGateFields: @widgetConfig.entryGateFields
        toggleButton:
          open: @widgetConfig.toggleOpenUrl
          close: @widgetConfig.toggleClosedUrl
        removeFooter: @widgetConfig.removeFooter
        knowledgeFlag: @widgetConfig.knowledgeFlag
        typingEffect: @widgetConfig.typingEffect
        uploadEnabled: @widgetConfig.uploadEnabled
        languageMenu: @widgetConfig.languageMenu
    defaultLanguageTeaser:
      get: ->
        @teaserMessages.find (teaser) => teaser.language == @defaultLanguage
      set: (teaser) ->
        index = @teaserMessages.findIndex (teaser) => teaser.language == @defaultLanguage
        if index == -1
          @teaserMessages.push(new WidgetTeaserConfig(@))
          index = @teaserMessages.length - 1
        @teaserMessages[index] = teaser
    widgetServer:
      get: ->
        if railsEnv == 'production'
          'rtc.mercury.ai/wci'
        else
          'rtc.mercury.ai/betawci'
    scriptTag:
      get: ->
        """
        <script type="application/javascript" src="https://cdn.mercury.ai/widget/v4/widget.js" id="mercury-widget-snippet" data-key="#{@nativeId}">
        </script>
        """
    styleLinkTag:
      get: ->
        """
        <link rel="stylesheet" type="text/css" href="https://cdn.mercury.ai/channels/widget/#{@nativeId}/style.css">
        """
    snippet:
      get: ->
        """
        #{@scriptTag}
        #{@styleLinkTag}
        """
    stylesheet:
      get: ->
        """
          #mercury-widget.custom-style {
            font-family: #{@widgetStyles.bodyFont};
          }
          #mercury-widget.custom-style #chat-widget {
            background-color: #{@widgetStyles.bodyColor};
            border-radius: #{@widgetStyles.bodyBorderRadius}px;
          }
          #mercury-widget.custom-style.overlay #chat-widget {
            box-shadow: #{@widgetStyles.bodyShadowColor} 1px 1px #{@widgetStyles.bodyShadowSize}px 0px;
          }
          #mercury-widget.custom-style #chat-widget .header {
            background: #{@widgetStyles.headerColor};
          }
          #mercury-widget.custom-style .open-toggle, #mercury-widget.custom-style .close-toggle {
            background-color: #{@widgetStyles.toggleBackgroundColor};
          }
          #mercury-widget.custom-style .message-list .response-wrap .response {
            border-radius: #{@widgetStyles.bubbleCornerRadius}px;
            font-size: #{@widgetStyles.bubbleFontSize}px;
          }
          #mercury-widget.custom-style .message-list .response-wrap .response img {
            border-radius: #{@widgetStyles.bubbleCornerRadius}px;
          }
          #mercury-widget.custom-style .message-list .response-wrap.BOT .response {
            background-color: #{@widgetStyles.bubbleBotBackgroundColor};
            color: #{@widgetStyles.bubbleBotFontColor};
          }
          #mercury-widget.custom-style .message-list .response-wrap.USER .response {
            background-color: #{@widgetStyles.bubbleUserBackgroundColor};
            color: #{@widgetStyles.bubbleUserFontColor};
          }
          #mercury-widget.custom-style .message-list .response-wrap.HANDOVER .response {
            background-color: #{@widgetStyles.bubbleAgentBackgroundColor};
            color: #{@widgetStyles.bubbleAgentFontColor};
          }
          #mercury-widget.custom-style .message-list .response-wrap.KNOWLEDGE .response {
            background-color: #{@widgetStyles.bubbleKnowledgeBackgroundColor};
            color: #{@widgetStyles.bubbleKnowledgeFontColor};
          }
          #mercury-widget.custom-style .message-list .message-date p {
            background: #{@widgetStyles.bubbleDateBackgroundColor};
            color: #{@widgetStyles.bubbleDateFontColor};
          }
          #mercury-widget.custom-style .message-list .response-wrap .timestamp {
            color: #{@widgetStyles.bubbleTimestampFontColor};
          }
          #mercury-widget.custom-style .attachment.button-wrap button, #mercury-widget.custom-style .attachment.button-wrap a {
            background-color: #{@widgetStyles.bubblePostbackBackgroundColor};
            color: #{@widgetStyles.bubblePostbackFontColor};
            font-size: #{@widgetStyles.bubbleFontSize}px;
          }
          #mercury-widget.custom-style .attachment.button-wrap button:hover, #mercury-widget.custom-style .attachment.button-wrap a:hover {
            background-color: #{@widgetStyles.bubblePostbackHoverBackgroundColor};
            color: #{@widgetStyles.bubblePostbackHoverFontColor};
            font-size: #{@widgetStyles.bubbleFontSize}px;
          }
          #mercury-widget.custom-style .quick-reply-button button {
            background-color: #{@widgetStyles.bubbleQuickBackgroundColor};
            box-shadow: #{@widgetStyles.bubbleQuickShadowColor} 0 0 #{@widgetStyles.bubbleQuickShadowBlur}px 0;
            border-radius: #{@widgetStyles.bubbleQuickCornerRadius}px;
            color: #{@widgetStyles.bubbleQuickFontColor};
            font-size: #{@widgetStyles.bubbleFontSize}px;
          }
          #mercury-widget.custom-style .quick-reply-button button:hover {
            background-color: #{@widgetStyles.bubbleQuickHoverBackgroundColor};
            color: #{@widgetStyles.bubbleQuickHoverFontColor};
          }
          #mercury-widget.custom-style .open-teaser .teaser-msg {
            background-color: #{@widgetStyles.bubbleTeaserBackgroundColor};
            color: #{@widgetStyles.bubbleTeaserFontColor};
          }
          #mercury-widget.custom-style .open-teaser .teaser-btn {
            background-color: #{@widgetStyles.bubbleTeaserButtonBackgroundColor};
            color: #{@widgetStyles.bubbleTeaserButtonFontColor};
            border-radius: #{@widgetStyles.bubbleTeaserButtonCornerRadius}px;
          }
          #mercury-widget.custom-style .open-teaser .teaser-btn:hover {
            background-color: #{@widgetStyles.bubbleTeaserButtonHoverBackgroundColor};
            color: #{@widgetStyles.bubbleTeaserButtonHoverFontColor};
          }
          #mercury-widget.custom-style .teaser .teaser-msg {
            background-color: #{@widgetStyles.bubbleTeaserBackgroundColor};
            color: #{@widgetStyles.bubbleTeaserFontColor};
          }
          #mercury-widget.custom-style .teaser .teaser-btn-wrapper .teaser-btn {
            background-color: #{@widgetStyles.teaserButtonBackgroundColor};
            color: #{@widgetStyles.teaserButtonFontColor};
            border-radius: #{@widgetStyles.teaserButtonCornerRadius}px;
            box-shadow: #{@widgetStyles.teaserButtonShadowColor} 0 0 #{@widgetStyles.teaserButtonShadowBlur}px 0;
          }
          #mercury-widget.custom-style .teaser .teaser-btn-wrapper .teaser-btn:hover {
            background-color: #{@widgetStyles.teaserButtonHoverBackgroundColor};
            color: #{@widgetStyles.teaserButtonHoverFontColor};
          }
        """

