
  import DialogNode from 'models/dialog_node.coffee'
  import ParameterList from './parameter_list.vue'
  import { EnvironmentBadge, Popover, Switch } from 'components/generic'
  import { ExclamationIcon, PlusIcon } from '@heroicons/vue/outline'
  import { ExclamationCircleIcon, LockClosedIcon, LockOpenIcon, LightningBoltIcon } from '@heroicons/vue/solid'

  export default
    props:
      node: Object
      isCurrent: Boolean
      displaySettings: Object

    emits: ['edit', 'add', 'editContextParameter']

    computed:
      wrapperClasses: ->
        switch @node.type
          when 'action'
            if @isCurrent # node is being edited
              'rounded-full bg-MercuryYellow-600 ring-2 ring-MercuryYellow-600 shadow-md'
            else if @node.ofOtherModule || @node.refNode
              'rounded-full bg-MercuryYellow-100'
            else # node belongs to module
              'rounded-full bg-MercuryYellow-500'
          when 'bot'
            if @isCurrent # node is being edited
              'rounded-md bg-MercuryGreen-600 ring-2 ring-MercuryGreen-600 shadow-md'
            else if @node.ofOtherModule || @node.refNode
              'rounded-md bg-MercuryGreen-100'
            else # node belongs to module
              'rounded-md bg-MercuryGreen-500'
          when 'trigger'
            if @isCurrent # node is being edited
              'rounded-full bg-MagentaViolet-400 ring-2 ring-MagentaViolet-400 shadow-md'
            else
              'rounded-full bg-MagentaViolet-400'
          when 'user'
            if @isCurrent # node is being edited
              'rounded-md bg-MercuryBlue-600 ring-2 ring-MercuryBlue-600 shadow-md'
            else if @node.ofOtherModule || @node.refNode
              'rounded-md bg-MercuryBlue-100'
            else # node belongs to module
              'rounded-md bg-MercuryBlue-500'
          when 'missing'
            'rounded-full ring-2 ring-MercuryRed-600 shadow-md'
      innerClasses: ->
        classString = if @node.isAction || @node.isTrigger
          'rounded-full pl-2 w-[calc(100%-0.5rem)]'
        else if @node.isBot
          'rounded-r-md -mr-2 w-[calc(100%-0.25rem)]'
        else
          'rounded-r-md w-[calc(100%-0.25rem)]'
        classString += if @node.ofOtherModule then' bg-gray-50 border-dashed' else ' bg-white'
        classString
      style: ->
        styleObject = if @node.hasInitiative
          background: """url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 -1 #{@node.width} #{@node.height}'><path d='M 0 #{@node.height / 2} l #{@node.width} 0' stroke='%23#{if @isCurrent then 'F6511D' else '595959'}' stroke-width='2' stroke-dasharray='none' fill='none'/></svg>")"""
          'background-repeat': 'no-repeat'
          'background-position': 'center center'
          'background-size': '100% 100%, auto'
        else
          {}
        Object.assign(
          styleObject
          padding: '0' if @node.isDummy || @node.isMissing
          transform: 'translateY(-50%)' if @node.isMissing
          width: "#{@node.width}px" if !@node.isMissing
          height: "#{@node.height}px" if !@node.isMissing
          left: "#{@node.x}px"
          top: if @node.isMissing then "#{@node.y + @node.height / 2}px" else "#{@node.y}px"
          transition: 'top .5s, left .5s'
        )

    methods:
      edit: ->
        @$emit('edit')

    components:
      EnvironmentBadge: EnvironmentBadge
      ExclamationCircleIcon: ExclamationCircleIcon
      ExclamationIcon: ExclamationIcon
      LightningBoltIcon: LightningBoltIcon
      LockClosedIcon: LockClosedIcon
      LockOpenIcon: LockOpenIcon
      ParameterList: ParameterList
      PlusIcon: PlusIcon
      Popover: Popover
      Switch: Switch
