
  import Bot from 'models/bot'
  import TableConfiguration from 'models/table_configuration'

  import ParameterTable from './parameter_table.vue'
  import { Button, DocumentationLink, H3, PageHeader } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot
      userSettings: Object

    data: ->
      tableConfig: new TableConfiguration(
        columns: [
          {key: 'description', label: 'Description', defaultShow: true}
          {key: 'dataType', label: 'Data type', defaultShow: true}
          {key: 'default', label: 'Default', defaultShow: true}
          {key: 'environment', label: 'Environment', defaultShow: false}
          {key: 'displayInInbox', label: 'Display in inbox', defaultShow: false}
        ]
      )

    computed:
      nonSearchContextParameters: ->
        @$root.contextParameters.filter (cp) -> !cp.contentSearchSchemaKey?
      searchContextParameters: ->
        @$root.contextParameters.filter (cp) -> cp.contentSearchSchemaKey?

    created: ->
      @initSettings()
      @bot.loadMetaInfo()

    methods:
      initSettings: ->
        @userSettings.contextParameterList ||= {}
        @userSettings.contextParameterList.columns ||= {}
        @tableConfig.columns.forEach (col) =>
          if @userSettings.contextParameterList.columns[col.key]?
            @tableConfig.columnsByKey[col.key].show = @userSettings.contextParameterList.columns[col.key]
      createContextParameter: ->
        @$router.push(name: 'newContextParameter')

    components:
      Button: Button
      DocumentationLink: DocumentationLink
      H3: H3
      PageHeader: PageHeader
      ParameterTable: ParameterTable
      PlusIcon: PlusIcon
