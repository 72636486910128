
  import DialogTarget from 'models/dialog_target'
  import WebChannel from 'models/web_channel'

  import draggable from 'vuedraggable'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import MenuEditor from '../menu_editor.vue'
  import TargetEditor from 'components/botbuilder/modules/target_editor.vue'
  import { Button, H3, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'
  import { PlusIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: WebChannel

    data: ->
      channel: @modelValue
      cookieType: 'noSession'
      languagesDisplay: []
      targetEditorRefs: {}
      PlusIcon: PlusIcon

    created: ->
      @cookieType = if @channel.widgetConfig.cookieLifetime == 'session'
        'session'
      else
        'noSession'
      @initLanguageMenu()

    beforeUpdate: ->
      @targetEditorRefs = {}

    methods:
      initLanguageMenu: ->
        @languagesDisplay = Object.entries(@$root.languages)
          .map ([code, lang]) =>
            code: code
            label: lang
            display: @channel.widgetConfig.languageMenu.languages.includes(code)
            easyAvailable: @$root.languageVariations[code]?.includes('EASY')
            easyEnabled: @channel.widgetConfig.languageMenu.languageVariations?[code]?.includes('EASY')
            target: if @channel.widgetConfig.languageMenu.targets?[code]?
              new DialogTarget(
                {}
                moduleKey: @channel.widgetConfig.languageMenu.targets[code].moduleKey
                nodeKey: @channel.widgetConfig.languageMenu.targets[code].nodeKey
              )
          .sort (a, b) =>
            @channel.widgetConfig.languageMenu.languages.indexOf(b.code) - @channel.widgetConfig.languageMenu.languages.indexOf(a.code)
          .reverse()
        if @channel.widgetConfig.languageMenu.languages.some (code) => !Object.keys(@$root.languages).includes(code)
          @updateLanguages()
      update: ->
        @$emit('update:modelValue', @channel)
      setCookieLifetime: ->
        @channel.widgetConfig.cookieLifetime = if @cookieType == 'session'
          'session'
        else
          WebChannel.DEFAULT_COOKIE_LIFETIME
        @update()
      addTarget: (languageMenuElement) ->
        languageMenuElement.target = new DialogTarget({}, {})
        @$nextTick =>
          @targetEditorRefs[languageMenuElement.code].open()
      removeTarget: (languageMenuElement) ->
        languageMenuElement.target = null
      updateLanguages: ->
        @channel.widgetConfig.languageMenu.languages = @languagesDisplay
          .map (o) -> o.code if o.display
          .compact()
        @channel.widgetConfig.languageMenu.languageVariations = Object.fromEntries(
          @languagesDisplay
            .map (o) -> [o.code, ['DEFAULT', 'EASY']] if o.easyEnabled
            .compact()
        )
        @channel.widgetConfig.languageMenu.targets = Object.fromEntries(
          @languagesDisplay
            .map (o) -> [o.code, o.target.export] if o.target?.export?
            .compact()
        )
        @update()
      setTargetEditorRef: (code, el) ->
        @targetEditorRefs[code] = el if el?

    components:
      Button: Button
      draggable: draggable
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      LanguageSelector: LanguageSelector
      MenuEditor: MenuEditor
      Switch: Switch
      TargetEditor: TargetEditor
      Textarea: Textarea
      XIcon: XIcon
