
  import Channel from 'models/channel'
  import Project from 'models/project'
  import { alert, confirm } from 'helpers'

  import ChannelLogo from './channel_logo.vue'
  import InitialContextEditor from './initial_context_editor.vue'
  import StatusBadge from './status_badge.vue'
  import { ActionMenu, Button, EnvironmentBadge, H1, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'
  import { ClipboardIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Channel

    emits: ['update:modelValue', 'remove']

    data: ->
      channel: @modelValue
      contextUpdates: []
      languagesForSelector: Object.entries(@$root.languages).map ([code, lang]) -> {key: code, label: lang}
      actionMenuItems: [
        {icon: XIcon, label: 'Delete channel', method: @remove}
      ]

    computed:
      languageVariationsForSelector: ->
        languageVariationLabels = Object.fromEntries(Project.LANGUAGE_VARIATIONS.map((f) -> [f.key, f.label]))
        (@$root.languageVariations[@channel.defaultLanguage] || ['DEFAULT']).map (variation) =>
          {key: variation, label: languageVariationLabels[variation]}

    watch:
      modelValue: ->
        @channel = @modelValue
        @setContextUpdates()

    created: ->
      @setContextUpdates()

    methods:
      setContextUpdates: ->
        @contextUpdates = Object.entries(@channel.contextUpdates).map ([key, value]) ->
          parameterKey: key
          value: value
      copyChannelId: (e) ->
        navigator.clipboard.writeText(@channel.id)
          .then =>
            e.target.blur()
            await alert('Copied channel ID to clipboard')
      remove: ->
        return unless await confirm('Do you really want to delete this channel?')
        @$emit('remove')
      update: ->
        @channel.contextUpdates = Object.fromEntries(
          @contextUpdates.map (update) -> [update.parameterKey, update.value]
        )
        @$emit('update:modelValue', @channel)
      updateLanguage: ->
        if (@$root.languageVariations[@channel.defaultLanguage] || ['DEFAULT']).length == 1
          @channel.defaultLanguageVariation = 'DEFAULT'
        @update()
      addContextUpdate: ->
        @contextUpdates.push(parameterKey: '', value: '')
      removeContextUpdate: (index) ->
        @contextUpdates.splice(index, 1)
        @update()

    components:
      ActionMenu: ActionMenu
      Button: Button
      ChannelLogo: ChannelLogo
      ClipboardIcon: ClipboardIcon
      EnvironmentBadge: EnvironmentBadge
      H1: H1
      InitialContextEditor: InitialContextEditor
      Input: Input
      Label: Label
      KeyListbox: KeyListbox
      StatusBadge: StatusBadge
      Switch: Switch
      Textarea: Textarea
