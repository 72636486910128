import BotbuilderApi from 'models/botbuilder_api.coffee'
import DeletedDialogModule from 'models/deleted_dialog_module.coffee'
import DialogModule from 'models/dialog_module.coffee'
import ContextParameter from 'models/context_parameter.coffee'
import ContextInterface from 'models/context_interface.coffee'
import DialogNode from 'models/dialog_node.coffee'
import Hook from 'models/hook.coffee'
import SlotRole from 'models/slot_role.coffee'

export default class BotConfig
  CORE_BEHAVIOURS: [
    'activeLearningBehavior'
    'knowledgeBehavior'
    'mediaBehavior'
    'recoveryBehavior'
    'optInLegalPrivacyBehavior'
    'inactivityBehavior'
  ]

  constructor: (@bot, data = {}) ->
    @metainfo = {}
    @moduleMeta = {}
    @hookMeta = {}
    @contextMeta = {}
    @update(data)

  update: (data = {}) ->
    @defaultLanguage = data.defaultLanguage if data.defaultLanguage?
    @contextInterfaces = (data.contextInterfaces || []).map (data) => new ContextInterface(@, data)
    @dialogModules = (data.dialogModules || []).map (data) => @newDialogModule(data)
    @hooks = (data.hooks || []).map (data) => new Hook(@, data)
    @CORE_BEHAVIOURS.forEach (type) =>
      @[type] = if data[type]? then @newDialogModule(data[type]) else null
    this

  setMetaInfo: (metainfo) ->
    @metainfo = metainfo
    @metainfo.deletedModules = (@metainfo.deletedModuleMetaInfos || []).map (data) => new DeletedDialogModule(@, data)
    (metainfo.moduleMetaInfos || []).forEach (moduleMeta) =>
      @moduleMeta[moduleMeta.moduleKey] = moduleMeta
    (metainfo.hooksMetaInfos || []).forEach (hookMeta) =>
      @hookMeta[hookMeta.hookKey] = hookMeta
    (metainfo.contextInterfaceMetaInfos || []).forEach (contextMeta) =>
      @contextMeta[contextMeta.parameterKey] = contextMeta

  newDialogModule: (data = {}) ->
    new DialogModule(@, data)

  addDialogModule: (data = {}) ->
    @dialogModules.unshift(@newDialogModule(data))
  addAsDeleted: (module) ->
    @metainfo.deletedModules.push(
      new DeletedDialogModule(@, moduleKey: module.key, label: module.label, description: module.description)
    )
  deleteDialogModule: (module) ->
    BotbuilderApi.deleteModule(module)
      .then =>
        @dialogModules = @dialogModules.filter (dialogModule) -> dialogModule.key != module.key

  module: (key) ->
    @coreBehaviours.find((module) -> module.key == key) || @dialogModules.find((module) -> module.key == key)
  fetchModule: (key) ->
    Promise.all([
      BotbuilderApi.getModule(@id, key)
        .then (moduleConfig) => @module(key).update(moduleConfig)
    ,
      BotbuilderApi.getModuleMetaInfo(@id, key)
        .then (data) => @module(key).setMetaInfo(data)
    ]).then ([module, foo]) => module

  contextInterface: (contextParameter) ->
    if contextInterface = @contextInterfaces.find (ci) -> ci.parameterKey == contextParameter.key
      contextInterface.contextParameter = contextParameter
      return contextInterface
    @contextInterfaces.push(new ContextInterface(@, contextParameter: contextParameter))
    @contextInterfaces[-1..][0]

  getNode: (moduleKey, nodeKey) ->
    module = @module(moduleKey)
    BotbuilderApi.getNode(@id, module, nodeKey)
      .then (data) => DialogNode.typed(module, data)

  searchNodes: (query, {types, node, statuses, includeModuleKey} = {}) =>
    BotbuilderApi.search(
      @bot.id
      query
      types: types
      node: node
      statuses: statuses.join(',') # only modules of these statuses are searched for nodes
      includeModuleKey: includeModuleKey # nodes of this module are added irrespective of the module's status
    )
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) => DialogNode.typed(@module(matchData.moduleKey), matchData.entity)

  searchContextParameters: (query) =>
    BotbuilderApi.search(@bot.id, query, types: ['ContextParameter'])
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) => new ContextParameter(matchData.entity)

  searchHooks: (query) ->
    BotbuilderApi.search(@bot.id, query, types: ['Hook'])
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) => new Hook(@, matchData.entity)

  searchKnowledgeSources: (query) ->
    BotbuilderApi.search(@bot.id, query, types: ['KnowledgeSource'])
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) => matchData.entity

  searchSlots: (query, {limit, moduleKey} = {}) ->
    BotbuilderApi.search(@bot.id, query, types: ['SlotRole'], limit: limit, moduleKey: moduleKey)
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) => new SlotRole(matchData.entity)

  save: ->
    BotbuilderApi.updateConfig(@)

  Object.defineProperties @prototype,
    id:
      get: ->
        @bot.id
    coreBehaviours:
      get: ->
        @CORE_BEHAVIOURS.map((type) => @[type]).compact()
    allModules:
      get: ->
        @coreBehaviours.concat(@dialogModules)
    deletedModules:
      get: ->
        @metainfo.deletedModules || []
    nodes:
      get: ->
        @dialogModules.concat(@coreBehaviours).map (module) -> module.nodes
          .flat(1)
    empty:
      get: ->
        return true if @contextInterfaces.concat(@dialogModules).concat(@hooks).length == 0 && !@CORE_BEHAVIOURS.some (type) => @[type]?
        false
    export:
      get: ->
        obj =
          defaultLanguage: @defaultLanguage
          contextInterfaces: @contextInterfaces.map (cp) -> cp.export
          dialogModules: @dialogModules.map (dm) -> dm.export
          hooks: @hooks.map (hook) -> hook.export
        @CORE_BEHAVIOURS.forEach (type) =>
          obj[type] = @[type]?.export
        obj
