
  import Project from 'models/project'
  import { availableLanguages as languageLabels } from 'rails_data'

  import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
  import { Badge, Button, H3, H4, KeyListbox, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  availableLanguages = [label: 'Select a language'].concat(Project.AVAILABLE_LANGUAGES)

  export default
    props:
      modelValue: Project

    data: ->
      project: @modelValue
      languageLabels: languageLabels
      availableLanguages: availableLanguages
      selectedLanguage: null
      easyLanguageEnabled: {}

    computed:
      totalLanguages: ->
        @project.maxLanguages
      usedLanguages: ->
        @project.languages.length
      mayAddLanguage: ->
        @usedLanguages < @totalLanguages
      mayRemoveLanguage: ->
        @usedLanguages > 1

    watch:
      modelValue: ->
        @project = @modelValue
      'project.languages':
        deep: true
        handler: ->
          @setEasyLanguageEnabled()
          @updateEasyLanguageEnabled()

    created: ->
      @setEasyLanguageEnabled()

    methods:
      addLanguage: ->
        return unless @selectedLanguage?
        return if @project.languages.includes(@selectedLanguage)
        @project.languages.push(@selectedLanguage)
        @selectedLanguage = null
        @update()
      removeLanguage: (index) ->
        return if @project.languages.length < 2
        @project.languages.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @project)
      setEasyLanguageEnabled: ->
        @easyLanguageEnabled = Object.fromEntries(
          @project.languages.map (lang) =>
            [lang, (@project.languageVariations[lang] || []).includes('EASY')]
        )
      updateEasyLanguageEnabled: ->
        @project.languageVariations = Object.fromEntries(
          Object.entries(@easyLanguageEnabled).map ([lang, enabled]) =>
            [lang, if enabled then ['DEFAULT', 'EASY'] else ['DEFAULT']]
        )
        @update()

    components:
      Badge: Badge
      Button: Button
      H3: H3
      H4: H4
      KeyListbox: KeyListbox
      Switch: Switch
      UpgradePrompt: UpgradePrompt
      XIcon: XIcon
